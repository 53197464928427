<template>
  <!-- 个人vip -->
  <div class="vip-bg">
    <div class="viptitle">
      <img src="@/assets/image/vip/vipbg.png" class="bg" alt="">
      <img src="@/assets/image/vip/vip.png" class="nobg" alt="">
    </div>
    <ul class="viplist">
      <li>
        <div class="jb-box">限时1.5折</div>
        <div class="title"><img src="@/assets/image/vip/nianplus.png" alt="">年PLUS会员</div>
        <p class="info">仅限个人</p>
        <div class="price"><span>￥</span>
          <p>{{gjData.price}}</p><em>/年</em>
        </div>
        <div class="origin-price">原价￥198/年</div>
        <p class="info">版权素材下载100条/天</p>
        <p class="info">样图下载10张/天</p>
        <p class="info">高清无水印下载</p>
        <p class="info">海量素材独家版权</p>
        <div class="btn" @click="buyVip(gjData)">立即购买</div>
      </li>
      <li :class="{not: loginInfo && loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level == 3}">
        <div class="jb-box">限时2折</div>
        <div class="title"><img src="@/assets/image/vip/nian.png" alt="">普通年会员</div>
        <p class="info">仅限个人</p>
        <div class="price"><span>￥</span>
          <p>{{ptData.price}}</p><em>/年</em>
        </div>
        <div class="origin-price">原价￥99/年</div>
        <p class="info">版权素材下载30条/天</p>
        <p class="info">样图下载10张/天</p>
        <p class="info">高清无水印下载</p>
        <p class="info">海量素材独家版权</p>
        <div class="btn"
          @click="loginInfo && loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level == 3 ? '' : buyVip(ptData)">立即购买
        </div>
      </li>
      <li :class="{not: loginInfo && loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level != 1}">
        <div class="jb-box">年终特惠价</div>
        <div class="title"><img src="@/assets/image/vip/month.png" alt="">月会员</div>
        <p class="info">仅限个人</p>
        <div class="price"><span>￥</span>
          <p>{{yueData.price}}</p><em>/月</em>
        </div>
        <div class="origin-price">原价￥19/月</div>
        <p class="info">版权素材下载10条/天</p>
        <p class="info">样图下载10张/天</p>
        <p class="info">高清无水印下载</p>
        <p class="info">海量素材独家版权</p>
        <div class="btn"
          @click="loginInfo && loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level != 1 ? '' : buyVip(yueData)">立即购买
        </div>
      </li>
    </ul>

    <div class="team-content">
      <div class="team-duibi" ref="pronbit">
        <h2>商用授权详细说明</h2>
        <div class="table-box" style="
            overflow: hidden;
            padding-top: 20px;
            margin: 0 auto;
            width: 1010px;
          ">
          <div class="table1">
            <table>
              <tr class="th">
                <th width="110px"></th>
                <th width="372px"></th>
              </tr>
              <tr>
                <td style="height: 115px"></td>
                <td></td>
              </tr>
              <tr class="trbg">
                <td class="title">时限</td>
                <td class="left"></td>
              </tr>
              <tr>
                <td class="title">定价</td>
                <td class="left"></td>
              </tr>
              <tr class="trbg">
                <td class="title">授权主体</td>
                <td class="left"></td>
              </tr>
              <tr>
                <td class="title" style="height: 60px">素材下载</td>
                <td class="left" style="height: 60px"></td>
              </tr>
              <!-- <tr>
                <td class="title">素材下载</td>
                <td class="left"></td>
              </tr> -->
              <tr class="trbg">
                <td class="title2" colspan="2">个人VIP授权范围</td>
              </tr>
              <tr>
                <td rowspan="9" class="title">设计图片授权范围盈利行为</td>
                <td class="left">
                  新媒体用途：网页、微信公众号、头条号、微博等媒体平台文章配图
                </td>
              </tr>
              <tr>
                <td class="left">电商平台：网店的海报、主图、商品详情页等</td>
              </tr>
              <tr>
                <td class="left">线上广告、电子出版物、商业提案等</td>
              </tr>
              <tr>
                <td class="left">
                  线下印刷：书籍、杂志、广告海报、宣传页、折页、门店陈设、易拉宝、展架等
                </td>
              </tr>
              <tr>
                <td class="left">电视、网络视频、电影</td>
              </tr>
              <tr>
                <td class="left">
                  户外广告（楼宇、车身、灯箱、围挡、橱窗、户外广告牌）
                </td>
              </tr>
              <tr>
                <td class="left">
                  转售设计元素：App皮肤/主题/壁纸/屏幕保护壁纸
                </td>
              </tr>
              <tr>
                <td class="left">
                  转售商品：模板、纺织品、手机壳、贺卡、明信片、日历、杯子、T桖等
                </td>
              </tr>
              <tr>
                <td class="left">
                  商标、LOGO、产品包装等（不包含商标、LOGO、烟草等违法或侵犯肖像权范围）
                </td>
              </tr>

              <tr>
                <td rowspan="10" class="title">音乐视频授权范围盈利行为</td>
                <td class="left">
                  社交平台发布：如微信、微博、博客等社交平台视频发布
                </td>
              </tr>
              <tr>
                <td class="left">
                  互联网发布：如网络媒体、电商平台、网络在线广告等
                </td>
              </tr>
              <tr>
                <td class="left">移动互联网发布：有声内容、H5、小程序内等</td>
              </tr>
              <tr>
                <td class="left">现场演出：现场表演演出中使用</td>
              </tr>
              <tr>
                <td class="left">
                  线下广告：大型商场、公司年会现场、颁奖活动等线下活动
                </td>
              </tr>
              <tr>
                <td class="left">
                  户外广告：光盘、U盘等物流媒介；智能硬件及电脑软件
                </td>
              </tr>
              <tr>
                <td class="left">游戏介质：游戏内置广告、游戏宣传</td>
              </tr>
              <tr>
                <td class="left">
                  影视制作：电影、电视剧、视频网站、企业宣传片、综艺
                </td>
              </tr>
              <tr>
                <td class="left">转售视频内容</td>
              </tr>
              <tr>
                <td class="left cole">
                  敏感用途：医疗、药物、烟草、整容美容、保健品类广告及宣传
                </td>
              </tr>
              <tr class="trbg">
                <td class="title2" colspan="2">授权服务</td>
              </tr>
              <tr>
                <td class="title">授地地域</td>
                <td class="left"></td>
              </tr>
              <tr>
                <td class="title">图片使用期限</td>
                <td class="left"></td>
              </tr>
              <tr>
                <td class="title">图片授权期限</td>
                <td class="left"></td>
              </tr>
            </table>
          </div>

          <div class="table2">
            <table>
              <tr class="th">
                <th width="207px">
                  <div class="heerd heerd1">年PLUS会员<span>（仅限个人）</span></div>
                </th>
              </tr>
              <tr>
                <td>
                  <div class="price-box">
                    <span class="price">{{ gjData.price }}元</span>
                    <span class="pay-btn" @click="buyVip(gjData)">立即购买</span>
                  </div>
                </td>
              </tr>
              <tr class="trbg">
                <td>1年</td>
              </tr>
              <tr>
                <td>{{ gjData.price }}</td>
              </tr>
              <tr class="trbg">
                <td>个人（非工商注册主体）</td>
              </tr>

              <tr class="other" style="height: 78px">
                <td style="padding: 0 10px">
                  <div>
                    版权素材下载100条/天<br />样图下载10张/天
                  </div>
                </td>
              </tr>
              <tr class="trbg">
                <td></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr style="height: 50px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>

              <tr class="trbg">
                <td></td>
              </tr>
              <tr>
                <td>中国（含港澳台地区）</td>
              </tr>
              <tr>
                <td>会员有效期内</td>
              </tr>
              <tr>
                <td>永久授权</td>
              </tr>
            </table>
          </div>
          <div class="table3">
            <table>
              <tr class="th">
                <th width="207px">
                  <div class="heerd heerd2">
                    普通年会员<span>（仅限个人）</span>
                  </div>
                </th>
              </tr>
              <tr>
                <td>
                  <div class="price-box">
                    <span class="price">{{ ptData.price }}元</span>
                    <span class="pay-btn"
                      :class="{not: loginInfo && loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level == 3}"
                      @click="loginInfo && loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level == 3 ? '' : buyVip(ptData)">立即购买</span>
                  </div>
                </td>
              </tr>
              <tr class="trbg">
                <td>1年</td>
              </tr>
              <tr>
                <td>{{ ptData.price }}</td>
              </tr>
              <tr class="trbg">
                <td>个人（非工商注册主体）</td>
              </tr>
              <tr class="other" style="height: 78px">
                <td style="padding: 0 10px">
                  版权素材下载30条/天<br />样图下载10张/天
                </td>
              </tr>
              <tr class="trbg">
                <td></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr style="height: 50px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>

              <tr class="trbg">
                <td></td>
              </tr>
              <tr>
                <td>中国（含港澳台地区）</td>
              </tr>
              <tr>
                <td>会员有效期内</td>
              </tr>
              <tr>
                <td>永久授权</td>
              </tr>
            </table>
          </div>
          <div class="table4">
            <table>
              <tr class="th">
                <th width="207px">
                  <div class="heerd heerd3">
                    月会员<span>（仅限个人）</span>
                  </div>
                </th>
              </tr>
              <tr>
                <td>
                  <div class="price-box">
                    <span class="price">{{ yueData.price }}元</span>
                    <span class="pay-btn"
                      :class="{not: loginInfo && loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level != 1}"
                      @click="loginInfo && loginInfo.is_sc_vip == 1 && loginInfo.sc_vip_level != 1 ? '' : buyVip(yueData)">立即购买</span>
                  </div>
                </td>
              </tr>
              <tr class="trbg">
                <td>1月</td>
              </tr>
              <tr>
                <td>{{ yueData.price }}</td>
              </tr>
              <tr class="trbg">
                <td>个人（非工商注册主体）</td>
              </tr>

              <tr class="other" style="height: 78px">
                <td style="padding: 0 10px">
                  版权素材下载10条/天<br />样图下载10张/天
                </td>
              </tr>
              <tr class="trbg">
                <td></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr style="height: 50px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr style="height: 59px">
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-Have"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>
              <tr>
                <td><i class="iconfont icon-VIPpage-no"></i></td>
              </tr>

              <tr class="trbg">
                <td></td>
              </tr>
              <tr>
                <td>中国（含港澳台地区）</td>
              </tr>
              <tr>
                <td>会员有效期内</td>
              </tr>
              <tr>
                <td>永久授权</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="shuom">
          “会员有效期内容”
          说明：会员期内下载并使用的图片，在会员到期后不可在使用于新的场景<br />
          “永久授权”
          说明：会员期间下载制作完成且公开发表的作品可在授权范围内永久授权
        </div>
      </div>
    </div>

    <pay v-if="payInfo.visible" :payInfo="payInfo" @close="closeFun" />
    <Footer />
  </div>
</template>
<script>
import Pay from "../../components/pay";
import Footer from "@/components/foot.vue";
import staticFunc from "@/config/static/func";
import { VipApi } from "@/config/axios/api";
import { mapState } from "vuex";

export default {
  name: "Vip",
  components: { Pay, Footer, },
  data () {
    return {
      gjData: {},
      ptData: {},
      yueData: {},
      payInfo: {
        visible: false,
      },
    };
  },
  computed: {
    ...mapState(['loginInfo']),
  },
  created () {
  },
  mounted () {
    VipApi.getVipList().then((res) => {
      if (res.status == 1) {
        this.gjData = res.data.find(item => item.level == 3);
        this.ptData = res.data.find(item => item.level == 2);
        this.yueData = res.data.find(item => item.level == 1);
      }
    });
    this.Statistics.defalutEvent({
      statsMark: "个人vip页",
      projectPage: '61',
      projectArea: "137",
      eventArea: '137',
      eventType: '1',
      eventId: '1277',
      eventName: 'vip_exposure',
    })
  },
  methods: {
    closeFun (refresh) {
      if (refresh) {
        this.$store.dispatch("loginInfo")
      }
      this.payInfo.visible = false;
    },
    toLogin () {
      if (staticFunc.isLogin()) {
        location.reload();
      } else {
        this.$store.commit("watchShowLogin", true);
        return;
      }
    },
    buyVip (item) {
      if (!this.staticFunc.isLogin()) {
        this.$store.commit("watchShowLogin", true);
        return;
      }
      this.payInfo.data = item;
      this.payInfo.visible = true;
      this.payInfo.payment_channel = 'VIP服务页';
    },
  },
};
</script>
<style scoped lang="scss">
.vip-bg {
  margin-top: 60px;

  .viptitle {
    width: 100%;
    height: 242px;
    position: relative;

    .bg {
      width: 100%;
      height: 100%;
      object-fit: cover;

    }

    .nobg {
      position: absolute;
      width: 1036px;
      height: 144px;
      bottom: -65px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .viplist {
    width: 1010px;
    margin: 110px auto 0;
    @include flex(center, space-between);

    li {
      position: relative;
      width: 312px;
      height: 394px;
      background: #FFFFFF;
      box-shadow: 0px 0px 16px 0px rgba(238, 238, 238, 0.8);
      border-radius: 12px;
      padding: 30px 27px;
      border: 1px solid #fff;
      text-align: center;
      transition: .3s;

      .jb-box {
        position: absolute;
        top: 0;
        left: -1px;
        width: 90px;
        height: 25px;
        background: #E25246;
        border-radius: 10px 0px 10px 0px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 25px;
        text-align: center;
      }

      &:hover {
        border: 1px solid $activeColor;
        transform: translateY(-12px);
      }

      &.not {
        &:hover {
          border: 1px solid #fff;
          transform: translateY(0);
        }

        .btn {
          cursor: no-drop;
          background: #F2F2F2;
          color: #C9C8C8;

          &:hover {
            background: #F2F2F2;
            color: #C9C8C8;
          }
        }
      }

      .title {
        @include flex(center, center);
        margin-bottom: 6px;
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1D2020;
        line-height: 36px;

        img {
          width: 37px;
          height: 36px;
          margin-right: 3px;
        }
      }

      .info {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(29, 32, 32, 0.5);
        line-height: 20px;
        margin-bottom: 8px;
      }

      .price {

        span {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1D2020;
          line-height: 20px;
          vertical-align: text-bottom;
        }

        p {
          font-size: 40px;
          display: inline;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1D2020;
          line-height: 56px;
        }

        em {
          font-style: normal;
          vertical-align: text-bottom;
          color: rgba(29, 32, 32, 0.5);
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 20px;
        }
      }

      .origin-price {
        margin-bottom: 10px;
        color: #B7B7B7;
        font-size: 14px;
        text-decoration: line-through;
      }

      .btn {
        width: 100%;
        height: 46px;
        background: $activeColor;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 46px;
        text-align: center;
        margin-top: 32px;
        cursor: pointer;
        transition: .3s;

        &:hover {
          background: #4f92fc;
        }
      }
    }
  }
}


.team-content {
  width: 1010px;
  height: 2300px;
  background: #ffffff;
  border-radius: 40px;
  margin: 0 auto;
  overflow: hidden;

  .team-type {
    @include flex("", space-between);

    .team-item {
      width: 386px;
      height: 516px;
      border-radius: 20px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.04);
      position: relative;

      .bztop {
        width: 100%;
        height: 123px;
        position: relative;

        img {
          display: block;
          width: 100%;
        }

        .zs {
          position: absolute;
          right: 0;
          top: 35px;
          width: 110px;
          height: 77px;
        }

        .title {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 30px;
          text-align: center;
          position: absolute;
          left: 0;
          top: 23px;
          width: 100%;
        }

        .team-name {
          width: 100%;
          text-align: center;
          position: absolute;
          left: 0;
          top: 59px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
        }
      }

      .price {
        margin: 17px 0 26px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .price-icon {
          display: block;
          overflow-wrap: break-word;
          color: rgba(243, 90, 18, 1);
          font-size: 16px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
          margin-top: 34px;
        }

        .price-num {
          display: block;
          overflow-wrap: break-word;
          color: rgba(248, 76, 12, 1);
          font-size: 50px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 70px;
          text-align: left;
        }

        .price-time {
          display: block;
          overflow-wrap: break-word;
          color: rgba(243, 90, 18, 1);
          font-size: 16px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
          margin-top: 32px;
        }

        .dingz {
          font-size: 32px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #0a051b;
          line-height: 70px;
          cursor: pointer;
        }
      }

      .banquan-btn {
        margin: 0 auto;
        width: 347px;
        height: 50px;
        border-radius: 6px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }

      .banquan-btn1 {
        background: #fc844b;
        border: 1px solid #fc844b;
      }

      .banquan-btn2 {
        background: #f84c0c;
        border: 1px solid #f84c0c;
      }

      .banquan-btn3 {
        background: #fea57b;
        border-radius: 6px;
        color: #fff;
      }

      .tipe {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 12px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0a051b;
        line-height: 17px;
        margin-bottom: 35px;
      }

      .dotted-box {
        width: 347px;
        height: 20px;
        margin: 0 auto 25px;
        position: relative;

        .line {
          width: 100%;
          height: 0;
          border: 1px dashed #dadcdf;
          position: absolute;
          left: 0;
          top: 10px;
        }

        .txt1 {
          position: absolute;
          top: 0;
          left: 117px;
          display: block;
          width: 114px;
          height: 20px;
          background: #fff;
          text-align: center;
          line-height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #646d79;
        }

        .txt2 {
          position: absolute;
          top: 0;
          left: 136px;
          display: block;
          width: 76px;
          height: 20px;
          background: #fff;
          text-align: center;
          line-height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #646d79;
        }
      }

      .introduce {
        /* width: 210px; */
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #373d44;
        line-height: 26px;
        margin: 25px auto 51px;
        text-align: center;
      }

      .introduce2 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #373d44;
        line-height: 30px;
        text-align: center;
      }

      /* .introduce-gj{
        width: 206px;
      } */
      &:hover {
        transform: translate(0, -14px);
      }
    }
  }

  .to-duibi {
    text-align: center;
    cursor: pointer;
    width: 142px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #2170cd;
    line-height: 20px;
    width: 1017px;
    margin: 65px auto 0;
  }

  .team-duibi {
    margin-top: 88px;

    h2 {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #0a051b;
      line-height: 45px;
      margin-bottom: 44px;
      text-align: center;
    }

    .table1 {
      width: 500px;
      float: left;
      overflow: hidden;
    }

    .trbg {
      background: #f6f7f9;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      border-radius: 4px 4px 0px 0px;
      float: left;

      .th {
        width: 100%;
        height: 60px;
        background: #f6f7f9;
        border-radius: 6px 6px 0px 0px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1f1f1f;

        .heerd {
          height: 100%;
          width: 102%;
          margin-top: -2px;
          margin-left: -2px;
          background: #fc844bff;
          line-height: 60px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          transform: translateY(0);

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
          }
        }

        .heerd1 {
          background: #f84c0c;
        }

        .heerd2 {
          background: #fc844b;
        }

        .heerd3 {
          background: #fea57b;
        }
      }

      tr {
        overflow: hidden;
        border: 1px solid #f6f7f9;

        td {
          text-align: center;
          height: 40px;
          padding: 10px 0 8px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0a051b;
          line-height: 20px;
          border: 1px solid #f6f7f9;

          &.left {
            text-align: left;
            width: 332px;
            padding-left: 20px;
            padding-right: 14px;
          }

          &.cole {
            color: #f84c0c;
          }

          .icon-VIPpage-no {
            color: #ed6c5f;
            font-size: 30px;
          }

          .icon-VIPpage-Have {
            color: #65b06e;
            font-size: 30px;
          }

          .price-box {
            background: #fff;
            transform: translateY(0);
            padding-top: 10px;

            .price {
              font-size: 24px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #f84c0c;
              line-height: 33px;
              text-align: center;
              margin: 8px auto 12px;
              display: block;
            }

            .pay-btn {
              display: block;
              width: 125px;
              height: 40px;
              background: rgba(252, 132, 75, 0.14);
              border-radius: 6px;
              border: 1px solid #f84c0c;
              margin: 0 auto;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #f84c0c;
              line-height: 40px;
              margin-bottom: 11px;
              cursor: pointer;

              &.not {
                cursor: no-drop;
                background: #F2F2F2;
                color: #C9C8C8;
                border: 1px solid #F2F2F2;
              }
            }

            .zhix-btn {
              display: block;
              width: 150px;
              height: 40px;
              background: #fff;
              border-radius: 6px;
              border: 1px solid #0a051b;
              margin: 0 auto;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #0a051b;
              line-height: 40px;
              margin-bottom: 11px;
              cursor: pointer;
            }

            .text {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #0a051b;
              line-height: 33px;
              text-align: center;
              margin: 8px auto 12px;
              display: block;
            }
          }

          .dingz {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2170cd;
            line-height: 20px;
            border-bottom: 1px solid #2170cd;
            margin: 0 auto;
            width: 4em;
            cursor: pointer;
          }

          .lianx {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #0a051b;
            line-height: 20px;

            .zhix {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2170cdff;
              line-height: 20px;
              border-bottom: 1px solid #2170cdff;
              cursor: pointer;
            }

            .phone {
              margin-left: 32px;

              img {
                width: 11px;
                height: 15px;
                vertical-align: -3px;
                margin-right: 3px;
              }
            }
          }
        }

        .title {
          width: 110px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0a051b;
          line-height: 22px;
          padding-left: 33px;
          text-align: left;
          padding-right: 5px;
        }

        .title2 {
          width: 174px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #0a051b;
          line-height: 25px;
          text-align: left;
          padding-left: 33px;
        }
      }
    }

    .table2 {
      width: 170px;
      float: left;

      &:hover {
        border-left: 1px solid #fc844bff;
        border-right: 1px solid #fc844bff;
        border-bottom: 1px solid #fc844bff;

        .heerd {
          transform: translateY(-14px);
        }

        .price-box {
          transform: translateY(-24px);
        }
      }
    }

    .table3 {
      width: 170px;
      float: left;

      &:hover {
        border-left: 1px solid #f84c0cff;
        border-right: 1px solid #f84c0cff;
        border-bottom: 1px solid #fc844bff;

        .heerd {
          transform: translateY(-14px);
        }

        .price-box {
          transform: translateY(-24px);
        }
      }
    }

    .table4 {
      width: 170px;
      float: left;

      &:hover {
        border-left: 1px solid #f84c0cff;
        border-right: 1px solid #f84c0cff;
        border-bottom: 1px solid #fc844bff;

        .heerd {
          transform: translateY(-14px);
        }

        .price-box {
          transform: translateY(-24px);
        }
      }
    }

    .shuom {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0a051b;
      line-height: 21px;
      margin-top: 15px;
      margin-left: 44px;
    }
  }
}
</style>
