<template>
  <footer>
    <div class="footer-inner">
      <div class="footer-top">
        <div class="product contact">
          <p class="hotline">产品介绍</p>
          <a :href="editorUrl + '/'" target="_blank">微信编辑器</a>
        </div>
        <div class="aboutus contact">
          <p class="hotline">关于我们</p>
          <a :href="editorUrl + '/about'" target="_blank" rel="nofollow">公司介绍</a>
          <a :href="editorUrl + '/home'" target="_blank">365编辑器首页</a>
          <a href="https://app.mokahr.com/social-recruitment/microdreams/44523" target="_blank">加入我们</a>
        </div>
        <div class="servicecenter contact">
          <p class="hotline">服务中心</p>
          <a href="//www.365editor.com/help" target="_blank">帮助中心</a>
        </div>
        <div class="contact">
          <p class="hotline">联系我们</p>
          <p>400-990-0611</p>
          <p><a href="mailto:zhangjian@microdreams.com" rel="nofollow">zhangjian@microdreams.com</a></p>
          <p>北京市朝阳区广渠路3号竞园5B栋</p>
          <a href="//wmcm.qiyukf.com/client?k=047bb8e122330a0f0ecaec916b1eb1a7&wp=1&robotShuntSwitch=1&robotId=162815"
            target="_blank" rel="nofollow">VIP专属客服</a>
        </div>
        <div class="qrcodes">
          <p>关注我们</p>
          <img src="@/assets/image/common/qr_aboutus.jpg" alt="">
        </div>
      </div>
      <div class="footer-bottom">
        <div class="record">
          <p>版权所有©北京饼干科技有限公司</p>
          <div class="record-box">
            <a href="http://beian.miit.gov.cn" target="_blank" rel="nofollow">京ICP备18055658号-9</a>
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049487" target="_blank"
              rel="nofollow">
              <div class="keep-record">
                <div>
                  <img src="https://editor-user.365editor.com/69/34/3468034/1650970915437216.png" alt="">
                </div>
                <p>京公网安备 11010502049487号</p>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: "foot",
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
footer {

  padding-top: 20px;
  height: 280px;
  min-width: 1200px;
  overflow: hidden;
  background: #2c3135;

  .footer-inner {
    width: 1200px;
    margin: 0 auto;

    h2 {
      color: #e9e9e9;
      height: 35px;
      line-height: 35px;
      font-size: 16px;
    }

    a {
      display: block;
      line-height: 25px;
      color: #a2a2a2;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer-top {
    @include flex('', space-evenly);

    .product {
      width: 140px;
    }

    .aboutus {
      width: 140px;
    }

    .servicecenter {
      width: 140px;
    }

    .contact {
      width: 240px;

      .hotline {
        height: 35px;
        line-height: 35px;
        font-size: 16px;
        color: #e9e9e9;

        a {
          display: inline;
          font-size: 16px;
        }

        em {
          color: #4bc1e1;
          margin: 0 10px;
        }
      }

      p {
        color: #a2a2a2;
        font-size: 12px;
        line-height: 25px;
      }
    }



    .qrcodes {
      float: left !important;
      text-align: center !important;
      margin-left: 40px !important;

      p {
        color: #e9e9e9 !important;
        height: 35px !important;
        line-height: 35px !important;
        font-size: 16px !important;
      }

      img {
        width: 90px !important;
        height: 90px !important;
        margin-top: 5px !important;
      }

    }
  }

  .footer-bottom {
    .record {
      clear: both;
      text-align: center;
      margin-left: -40px;
      margin-top: 30px;

      .record-box {
        margin-top: 10px;
      }

      p {
        color: #939393;
      }

      div {
        a {
          text-decoration: none;
          display: block;
        }

        .keep-record {
          display: flex;
          justify-content: center;
          align-items: center;

          .record-img {
            width: 20px;
            height: 20px;
          }

          p {
            color: #939393;
            line-height: 20px;
            margin-left: 8px;
            margin-top: 4px;
          }
        }
      }
    }
  }
}
</style>
